<template lang="pug">
.wrapper
	div(v-if="story && story.content")
		StoryblokComponent(:blok="story.content")
	.errorpage.container.section(v-else-if="errorFetchingStory")
		SingaLogo.logo
		h1.not-found {{ t('404.notFound') }}
		span
			| {{ t('404.notFound.details1') }}
			|
			NuxtLink.link(:to="localePath('/search/results/')") {{ t('404.notFound.details2') }}
			|
			| {{ t('404.notFound.details3') }}
			|
			NuxtLink.link(:to="'https://help.singa.com/'") {{ t('404.notFound.details4') }}
			| .
		SingaButton.is-transparent-dark.is-regular(@click="goHome") {{ t('general.home') }}
	div(v-else)
		CommonLoadingAnimation.center
</template>

<script setup lang="ts">
const { t } = useI18n()
const { path, params } = useRoute()
const localePath = useLocalePath()
const { loadVenueCities } = useVenues()
const { segmentEvent } = useSegment()
const { isLoggedIn } = useAuth()

const errorFetchingStory = ref(false)

definePageMeta({
	layout: false,
	layoutTransition: {
		name: 'fade',
		mode: 'out-in'
	}
})

const { $preview } = useNuxtApp()

let slugs = [] as string[]
if (params.slug !== '') {
	slugs = params.slug as string[]
}

const slugToFetch = slugs.join('/')
const fixSlug = (slug: string) => {
	let newSlug = slug
	if (!newSlug.endsWith('/')) {
		newSlug = newSlug + '/'
	}
	if (newSlug.startsWith('/')) {
		newSlug = newSlug.substring(1)
	}
	return newSlug.toLowerCase()
}

const version = $preview ? 'draft' : 'published'

// Getting the story data for all languages
const { story, storyError: error } = await useStoryblokFetch(fixSlug(slugToFetch), {
	version,
	resolve_links: 'url'
})
if (error.value) {
	errorFetchingStory.value = true
}

if (story.value?.content?.transparent_nav) {
	setPageLayout(story.value?.content?.transparent_nav ? 'lander-layout' : 'default')
} else {
	setPageLayout(story.value?.content?.layout ? story.value?.content?.layout : 'default')
}

// Load venue cities if we're on a karaoke-near-me page
if (path.includes('karaoke-near-me') && !import.meta.prerender) {
	await loadVenueCities()
}

const { setMetaTitle, setMetaDescription } = useMetaTags()
const pageTitle = story.value?.content?.SEO?.title || t('meta.index.title')
const metaTitle = setMetaTitle(pageTitle)
const metaDesc = setMetaDescription(story.value?.content?.SEO?.description || t('meta.index.description'))

const metaTags = [
	...metaTitle,
	...metaDesc
]

if (story.value?.content?.noindex) {
	metaTags.push({
		key: 'robots',
		name: 'robots',
		content: 'noindex, nofollow'
	})
}

useHead({
	title: pageTitle,
	meta: metaTags
})

onMounted(() => {
	if (story.value) {
		if ($preview) {
			useStoryblokBridge(
				story.value.id,
				evStory => (story.value = evStory),
				{ resolveLinks: 'url' }
			)
		}
	}
	if (story.value?.slug === 'karaoke-near-me' && isLoggedIn.value) {
		segmentEvent('Venue Page Opened')
	}
})

const goHome = () => navigateTo({ path: localePath('/') })
</script>

<style lang="sass" scoped>
.wrapper
	display: block
	min-height: 500px

.errorpage
	max-width: 420px
	text-align: center
	display: flex
	flex-direction: column
	align-items: center
	row-gap: $spacing-24
	min-height: 550px
	margin: 0 auto
	@include fontSize(s)

.not-found
	margin-top: $spacing-8
	@include font(basier, medium)
	@include fontSize(3xl)

.logo
	margin-top: $spacing-80
	margin-bottom: $spacing-16

.button
	margin-bottom: $spacing-80
	width: 120px

.link
	text-decoration: underline
	color: white
	&:hover
		color: $color-grey-50

.center
	margin-top: $spacing-80
</style>
